import { Suspense, lazy } from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";

import clsx from "clsx";
import { InfinitySpin } from "react-loader-spinner";
import MainLayout from "./layout/MainLayout";
import ErrorPage from "./pages/feedback/ErrorPage";
import NotFound from "./pages/feedback/NotFound";

const Login = lazy(() => import("./pages/Login"));
const Dashboard = lazy(() => import("./pages/main/Dashboard"));

const User = lazy(() => import("./pages/main/Users/UserList"));
const CreateUser = lazy(() => import("./pages/main/Users/CreateUser"));
const EditUser = lazy(() => import("./pages/main/Users/EditUser"));

const Specs = lazy(() => import("./pages/main/VehicleSpecs/SpecsList"));
const SpecsView = lazy(() => import("./pages/main/VehicleSpecs/SpecsView"));
const UploadData = lazy(() => import("./pages/main/VehicleSpecs/UploadData"));
const SampleFile = lazy(() => import("./pages/main/SampleFile/SampleFile"));
const UploadRetailPriceFile = lazy(() => import("./pages/main/RetailPrice/Upload"));

const Audit = lazy(() => import("./pages/main/Audit/Audit"));

const Rule = lazy(() => import("./pages/main/Rule/Rule"));

const Make = lazy(() => import("./pages/main/Make"));
const Model = lazy(() => import("./pages/main/Model"));
const Category = lazy(() => import("./pages/main/Category"));
const MakeModelCategory = lazy(() => import("./pages/main/MakeModelCategory"));
const DepreciationBandPage = lazy(() => import("./pages/main/DepreciationBand"));

const PageLoader = ({ fullScreen = false }) => (
  <div
    className={clsx(
      "flex h-full items-center justify-center",
      fullScreen && "h-screen",
    )}
  >
    <InfinitySpin visible={true} width="200" color="#0C71C3" />
  </div>
);

function App() {
  const router = createBrowserRouter(
    [
      {
        path: "/",
        element: <MainLayout />,
        errorElement: <ErrorPage />,
        children: [
          {
            index: true,
            element: (
              <Suspense fallback={<PageLoader />}>
                <Dashboard />
              </Suspense>
            ),
          },
          {
            path: "/user",
            element: (
              <Suspense fallback={<PageLoader />}>
                <User/>
              </Suspense>
            ),
          },
          {
            path: "/user/create",
            element: (
              <Suspense fallback={<PageLoader />}>
                <CreateUser />
              </Suspense>
            ),
          },
          {
            path: "/user/edit/:id",
            element: (
              <Suspense fallback={<PageLoader />}>
                <EditUser />
              </Suspense>
            ),
          },
          {
            path: "/vehicle-specs",
            element: (
              <Suspense fallback={<PageLoader />}>
                <Specs />
              </Suspense>
            ),
          },
          {
            path: "/vehicle-specs/view/:id",
            element: (
              <Suspense fallback={<PageLoader />}>
                <SpecsView />
              </Suspense>
            ),
          },
          {
            path: "/vehicle-specs/upload-data",
            element: (
              <Suspense fallback={<PageLoader />}>
                <UploadData />
              </Suspense>
            ),
          },
          {
            path: "/upload-sample",
            element: (
              <Suspense fallback={<PageLoader />}>
                <SampleFile />
              </Suspense>
            ),
          },
          {
            path: "/retail-price/upload-file",
            element: (
              <Suspense fallback={<PageLoader />}>
                <UploadRetailPriceFile />
              </Suspense>
            ),
          },
          {
            path: "/audit",
            element: (
              <Suspense fallback={<PageLoader />}>
                <Audit />
              </Suspense>
            ),
          },
          {
            path: "/rule",
            element: (
              <Suspense fallback={<PageLoader />}>
                <Rule />
              </Suspense>
            ),
          },
          {
            path: "/make",
            element: (
              <Suspense fallback={<PageLoader />}>
                <Make />
              </Suspense>
            ),
          },
          {
            path: "/model",
            element: (
              <Suspense fallback={<PageLoader />}>
                <Model />
              </Suspense>
            ),
          },
          {
            path: "/category",
            element: (
              <Suspense fallback={<PageLoader />}>
                <Category />
              </Suspense>
            ),
          },
          {
            path: "/make-model-category",
            element: (
              <Suspense fallback={<PageLoader />}>
                <MakeModelCategory />
              </Suspense>
            ),
          },
          {
            path: "/depreciation-band/:id",
            element: (
              <Suspense fallback={<PageLoader />}>
                <DepreciationBandPage />
              </Suspense>
            ),
          },
        ],
      },
      {
        path: "/login",
        errorElement: <ErrorPage />,
        element: (
          <Suspense fallback={<PageLoader fullScreen />}>
              <Login />
          </Suspense>
        ),
      },
      // {
      //   path: "/session",
      //   errorElement: <ErrorPage />,
      //   element: (
      //     <Suspense fallback={<PageLoader fullScreen />}>
      //       {/* <Session /> */}
      //     </Suspense>
      //   ),
      // },
      {
        path: "*",
        element: <NotFound />,
      },
    ],
    {
      basename: "/",
    },
  );

  return <RouterProvider router={router} />;
}

export default App;
